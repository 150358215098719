import { CartCreateStore } from "./cart.create.store";
import React, { Context } from "react";
import queryString from "querystring";
import { WarehousesService } from "../../../services/warehouses/warehouses.service";
import { ArticlesService } from "../../../services/articles/articles.service";
import {AddArticleQuery, AddArticleValues} from "../../../models/cart/create/add-article-query/add.article.query";
import { AddressesService } from "../../../services/addresses/addresses.service";
import { generateData } from "../../../models/cart/helper/cart.helper";
import { CartCreateService } from "../../../services/cart/create/cart.create.service";
import {CartCreate, CartCreateType} from "../../../models/cart/create/cart.create";
import { OrdersCartRequestQuery } from "../../../models/cart/create/query/cart.create.query";
import history from "../../../utils/history";
import { CheckboxChangeEvent } from "antd/lib/checkbox";

export class CartCreateController {
    constructor(
        private readonly _cartCreateStore: CartCreateStore,
        private readonly _cartCreateService: CartCreateService,
        private readonly _warehousesService: WarehousesService,
        private readonly _articlesService: ArticlesService,
        private readonly _addressesServices: AddressesService,
        private updateNotCompletedOrdersAmount: (amount: number) => void
    ) {
        this.searchArticles = this.searchArticles.bind(this);
        this.onLngChange = this.onLngChange.bind(this);
        this.createOrder = this.createOrder.bind(this);
        this.goToCheckout = this.goToCheckout.bind(this);
        this.loadData();
    }

    public onLngChange(): void {
        this.reloadCart();
    }

    public async searchArticles(value: string): Promise<{ key: number; label: string; value: number, multiple: number }[]> {
        return (await this._articlesService.searchArticles(value)).map((item) => ({
            key: item.id,
            value: item.id,
            label: item.name + " - " + item.description,
            multiple: item.multiple
        }));
    }

    public async addToCart(values: AddArticleValues): Promise<void> {
        await this._cartCreateService.addToCart({...values, type: this._cartCreateStore.type});
        await this.reloadCart();
    }

    public async clearCart(): Promise<void> {
        await this._cartCreateService.clearCart();
        await this.reloadCart();
    }

    public async removeItem(articleId: number | string): Promise<void> {
        await this._cartCreateService.deleteItem(articleId, this._cartCreateStore.type);
        await this.reloadCart();
    }

    public async updateItem(articleId: number | string, values: { qty: number }): Promise<void> {
        await this._cartCreateService.updateItem(articleId, {...values, type: this._cartCreateStore.type});
        await this.reloadCart();
    }

    public async updateCart(values: { warehouses: string[], useBackorder: boolean }): Promise<void> {
        this._cartCreateStore.setDefaultWarehouses(values.warehouses);
        this._cartCreateStore.setUseBackorder(values.useBackorder || false);
        this.updateUrlQueryString();
        this.updateDataFromURL();
        await this.reloadCart();
    }

    public async reloadCart(): Promise<void> {
        this._cartCreateStore.setPreviewLoading(true);
        this._cartCreateStore.setCart(
            await this._cartCreateService.getPreview(this._cartCreateStore.defaultWarehouses, this._cartCreateStore.useBackorder, this._cartCreateStore.type)
        );
        this._cartCreateStore.setTableData(
            generateData(
                this._cartCreateStore.cart,
                this._cartCreateStore.warehousesAll,
                this._cartCreateStore.defaultWarehouses
            )
        );
        this._cartCreateStore.setPreviewLoading(false);
    }

    private async loadData(): Promise<void> {
        this._cartCreateStore.setLoading(true);
        //this._cartCreateStore.resetOrderResult({});
        this._cartCreateStore.setWarehousesAll(await this._warehousesService.getWarehouses());
        if (this._cartCreateStore.warehousesAll.length === 0) {
            this._cartCreateStore.setFieldsAreValid(false);
            this._cartCreateStore.setLoading(false);
            return;
        }

        this.updateDataFromURL();

        this.updateUrlQueryString();

        await this.reloadCart();
        this._cartCreateStore.setLoading(false);
    }

    public updateDataFromURL() {
        const params: any = queryString.parse(window.location.search.substr(1));
        if (params.warehouses) {
            const warehouses: string[] = Array.isArray(params.warehouses) ? params.warehouses : [params.warehouses];
            this._cartCreateStore.setDefaultWarehouses(warehouses);
        } else {
            this._cartCreateStore.setDefaultWarehouses([this._cartCreateStore.warehousesAll[0].id]);
        }

        if (params.useBackorder) {
            this._cartCreateStore.setUseBackorder(params.useBackorder === "1");
        }
    }

    private updateUrlQueryString(): void {
        const obj: any = {};
        if (this._cartCreateStore.type === 'regular') {
            obj.warehouses = this._cartCreateStore.defaultWarehouses;
            obj.useBackorder = this._cartCreateStore.useBackorder ? "1" : "0";
        }
        const link = queryString.stringify(obj);
        if (link) {
            this._cartCreateStore.setUrlString(link);
            window.history.pushState("", "", "?" + link);
        }
    }

    public async createOrder() {
        this._cartCreateStore.setCheckoutButtonDisabled(true);
        const requestData = this.generateCreateOrderRequestQuery({
            cart: this._cartCreateStore.cart,
            warehouses: this._cartCreateStore.defaultWarehouses,
            type: this._cartCreateStore.type
        });

        try {
            const result = await this._cartCreateService.createOrder(requestData);
            this.updateNotCompletedOrdersAmount(result.orders.length);
            if (result.missingItems.length > 0) {
                this._cartCreateStore.setMissingItems(result.missingItems);
            } else {
                this.goToCheckout();
            }
        } catch (e) {
            this._cartCreateStore.setCheckoutButtonDisabled(false);
        }
    }

    private generateCreateOrderRequestQuery = ({
        cart,
        warehouses,
        type
    }: {
        cart: CartCreate;
        warehouses: string[];
        type: CartCreateType;
    }): OrdersCartRequestQuery => {
        return {
            warehouses,
            items: cart.items.map((item) => ({
                article: item.article.id,
                qty: item.requestedQty,
                customAmountValue: undefined,
            })),
            type
        };
    };

    public goToCheckout(): void {
        history.push("/cart/checkout");
    }
}

export const CartCreateControllerContext = React.createContext<null | CartCreateController>(
    null
) as Context<CartCreateController>;
