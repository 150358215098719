import {TFunction} from "react-i18next";
import {ColumnsType} from "antd/lib/table/interface";
import React from "react";
import {Link} from "react-router-dom";
import {BackorderItem} from "../../../models/backorder/backorder.item";
import {SystemUser} from "../../../models/user/user";
import {Article} from "../../../models/articles/article";
import {Date} from "../../core/commons/date/date";

export const columnsFactory = (t: TFunction<"translation">): ColumnsType<BackorderItem> => {
    return [
        {
            title: t("ARTICLES.TITLE_SINGLE"),
            dataIndex: "article",
            key: "article",
            render: (item: Article) => item.name !== "DELIVERY" ? <Link to={"/catalog/article/" + item.name} className="link article-link">{item.name}</Link> : item.name
        },
        {
            title: t("DESCRIPTION"),
            dataIndex: "article",
            key: "article",
            render: (item: Article) => <>{item.description}</>
        },
        {
            title: t("QUANTITY"),
            dataIndex: "qty",
            key: "qty"
        },
        {
            title: t("BACKORDER.APPROXIMATELY_ARRIVAL_DATE"),
            dataIndex: "approximatelyArrivalDate",
            key: "approximatelyArrivalDate",
            render: (item: string) => <>{item ? (<Date dateString={item} />) : '-'}</>
        },
        {
            title: t("BACKORDER.APPLIED_BY"),
            dataIndex: "user",
            key: "user",
            render: (item: SystemUser) => <>{item.firstName} {item.lastName}</>
        }
    ];
}